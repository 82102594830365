export const getStatusColour = (status) => {
	switch (status?.toLowerCase()) {
		default:
		case "unknown":
		case "booked":
			return "var(--backgroundMarigold)";
		case "arrived":
			return "var(--foregroundLightGreen)";
		case "attended":
			return "var(--foregroundBerry)";
		case "completed":
			return "var(--Compucare)";
		case "cancelled":
		case "dna":
			return "var(--backgroundRedThree)";
	}
};

export const getEocStatusColour = (isOpen) => (isOpen ? "var(--foregroundLightGreen)" : "var(--Compucare)");

export const getBookingColour = (type, past = false) => {
	switch (type?.toLowerCase()) {
		case "opsb":
		case "sb":
		case "surgical":
			return past ? "var(--secondaryAppColourAlt)" : "var(--foregroundRedThree)";
		case "op":
		case "outpatient":
		default:
			return past ? "var(--backgroundBrandTwoAlternate)" : "var(--Compucare)";
		case "sus":
		case "suspension":
			return "var(--foregroundDisabled)";
		case "res":
		case "reservation":
		case "priv":
			return "var(--foregroundFour)";
	}
};
