import classes from "./PatientOrders.module.scss";
import { Divider } from "@streets-heaver/shui2";
import { ServiceRequestRow } from "../../infoTable/cellComponents";
import { ActionPaths } from "../../../layouts/Layout/actionPaths";
import { useParams } from "react-router";
import { usePatientOrdersWidget } from "../../../api/hooks";
import { NoAccessMessage } from "../../NoAccessMessage/NoAccessMessage";
import { isProduction } from "../../../globals/constants";
import { ComingSoon } from "../ComingSoon/ComingSoon";
import { faClipboardListCheck } from "@shfortawesome/pro-duotone-svg-icons";

export const PatientOrders = ({ patientName, hasPermission }) => {
	const { patientId } = useParams();
	const {
		errorPatientOrders,
		dataPatientOrders,
		isLoadingPatientOrders,
		isFetchingMorePatientOrders,
		hasMorePatientOrders,
		fetchMorePatientOrders,
	} = usePatientOrdersWidget(patientId, 10, hasPermission);

	if (isProduction) {
		return <ComingSoon title={`Orders for ${patientName ?? "Patient"}`} icon={faClipboardListCheck} />;
	}

	if (!hasPermission) {
		return <NoAccessMessage />;
	}

	return (
		<div className={classes.patientOrders}>
			<div className={classes.header}>
				<p className={classes.title}>Orders for {patientName ?? "Patient"}</p>
				<p className={classes.count}>{dataPatientOrders?.pages[0]?.totalServiceRequests ?? "0"}</p>
			</div>
			<div
				className={classes.serviceRequestList}
				onScroll={(e) => {
					if (
						e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop < 64 &&
						!isLoadingPatientOrders &&
						!isFetchingMorePatientOrders &&
						hasMorePatientOrders
					) {
						fetchMorePatientOrders();
					}
				}}
			>
				{isLoadingPatientOrders ? (
					<p>Loading orders for this patient...</p>
				) : errorPatientOrders ? (
					<p>Error fetching orders for this patient</p>
				) : (
					dataPatientOrders?.pages?.map((page, pageI) =>
						page?.data?.length < 1 ? (
							<p key="empty">No orders for this patient.</p>
						) : (
							page?.data?.map((serviceRequest, i) => (
								<div
									key={`patientView-orders-${i}`}
									style={{
										backgroundColor: "var(--backgroundTwo)",
										borderRadius:
											pageI < 1 && i < 1
												? "8px 8px 0 0"
												: pageI === dataPatientOrders?.pages?.length - 1 && i === page?.data?.length - 1
													? "0 0 8px 8px"
													: "unset",
									}}
								>
									<ServiceRequestRow
										request={serviceRequest}
										resultCallback={() => "../orders/" + ActionPaths.ReviewResults(patientId)}
										showShortButtons
									/>
									{!(pageI === dataPatientOrders?.pages?.length - 1 && i === page?.data?.length - 1) && <Divider />}
								</div>
							))
						),
					)
				)}
			</div>
		</div>
	);
};
