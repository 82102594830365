import { Dialog } from "@streets-heaver/shui2";
import { useLocation, useNavigate } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { NoAccessMessage } from "../../components";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { PrintForm } from "../../components/FormComponents";
import { printModalSchema, printModalValues } from "./PrintModalSchema";

export const PrintModal = ({ type }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { userSecurityFlags } = useCurrentClinicians();

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: printModalValues(),
		resolver: zodResolver(printModalSchema),
	});

	const onSubmit = (data) => {
		toast.promise(
			new Promise((resolve) => {
				setTimeout(() => resolve(), 2000);
			}),
			{
				loading: "Queuing report to print",
				error: "Error queuing report",
				success: "Queued report to print",
			},
		);
		console.log("print modal data", data);
		onClose();
	};

	const onClose = () => navigate({ pathname: "..", search: location.search });

	if (!userSecurityFlags?.includes(`toolbar-printing`))
		return (
			<Dialog
				visible
				fixedHeight={"100px"}
				header="Print lists"
				onCloseButtonClick={onClose}
				buttons={{
					primaryButton: { children: "Print", disabled: true },
					secondaryButton: { children: "Cancel", onClick: onClose },
				}}
			>
				<NoAccessMessage />
			</Dialog>
		);

	return (
		<Dialog
			visible
			header="Print lists"
			onCloseButtonClick={onClose}
			buttons={{
				primaryButton: { children: "Print", onClick: methods.handleSubmit(onSubmit) },
				secondaryButton: { children: "Cancel", onClick: onClose },
			}}
		>
			<FormProvider {...methods}>
				<PrintForm type={type} />
			</FormProvider>
		</Dialog>
	);
};
