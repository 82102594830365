import classes from "./PatientView.module.scss";
import { Outlet, useParams } from "react-router";
import { PatientBanner, Card } from "@streets-heaver/shui2";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import {
	LoadingSpinner,
	AbnormalResults,
	OrderStatus,
	PatientNotes,
	PatientOrders,
	UnseenReports,
	NoAccessPage,
} from "../../components";
import { useMeasure } from "@uidotdev/usehooks";
import { usePatientBanner } from "../../api/hooks/usePatientBanner";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { useDataSources } from "@streetsheaver/compucore";
import { formatNameAsReversed } from "../../utils";
import { NextAppointment } from "../../components/Widgets/NextAppointment/NextAppointment";

export default function PatientView() {
	const { currentClinician, userSecurityFlags } = useCurrentClinicians();
	const { patientId } = useParams();
	const patientBannerQuery = usePatientBanner(patientId);

	const { currentDataSource } = useDataSources();

	const [patientViewRef, { width: containerWidth }] = useMeasure();

	return (
		<div className={classes.PatientView} ref={patientViewRef} data-testid={"pagePatientView"}>
			{!currentClinician ? (
				<LoadingSpinner />
			) : (
				<>
					{!userSecurityFlags.includes("patientview") ? (
						<NoAccessPage />
					) : (
						<div className={classes.Body}>
							<Outlet />
							<PatientBanner
								isGhost={!patientBannerQuery.isSuccess}
								patientData={{
									id: patientBannerQuery.data?.patientPrimaryId,
									idLink: `../patient/${patientBannerQuery.data?.patientId}`,
									surname: patientBannerQuery.data?.surname || "",
									addressLineOne: patientBannerQuery.data?.addressLine1,
									isPatPortalRegistered: patientBannerQuery.data?.isPatientPortalRegistered,
									...patientBannerQuery.data,
								}}
								columnData={[
									[
										{ title: "Email", result: patientBannerQuery.data?.email },
										{ title: "Main phone", result: patientBannerQuery.data?.mainPhone },
										{ title: "Work phone", result: patientBannerQuery.data?.workPhone },
										{ title: "Mobile phone", result: patientBannerQuery.data?.mobilePhone },
									],
									[
										{ title: "Patient ID", result: patientBannerQuery.data?.patientId },
										{ title: "NHS Number", result: patientBannerQuery.data?.nhsNo },
										{ title: "Hospital ID", result: patientBannerQuery.data?.hospitalId },
										{ title: "Case number", result: patientBannerQuery.data?.caseNo },
										{ title: "Verification status", result: patientBannerQuery.data?.verificationStatus },
									],
									[
										{ title: "Registered GP", result: patientBannerQuery.data?.gp },
										{ title: "GP Practice", result: patientBannerQuery.data?.gpPractice },
									],
									[
										{ title: "Sex (biological)", result: patientBannerQuery.data?.sex },
										{ title: "Pronouns", result: patientBannerQuery.data?.pronouns },
										{ title: "Gender", result: patientBannerQuery.data?.gender },
									],
								]}
								alerts={patientBannerQuery.data?.alerts}
								actions={{
									editPatient: { to: `../${ActionPaths.EditPatientPreselected(patientId)}` },
									medicalRecords: {
										to: `https://compucare-dev.streets-heaver.com/medical-records/${currentDataSource?.DataSourceId}/patient/${patientId}`,
									},
								}}
								automationId="patient-banner"
							/>
							<div className={classes.Widgets}>
								{containerWidth >= 628 && (
									<Card border={false} className={classes.NextAppointmentCard}>	
										<NextAppointment patientId={patientId} />
									</Card>
								)}
								{containerWidth >= 628 && (
									<Card border={false} className={classes.AbnormalResultsCard}>
										<AbnormalResults
											hasPermission={userSecurityFlags.includes("patientview-abnormalresults")}
											patientId={patientId}
										/>
									</Card>
								)}
								{containerWidth >= 956 && (
									<Card border={false} className={classes.OrderStatusCard}>
										<OrderStatus />
									</Card>
								)}
								<Card border={false} className={classes.UnreadReportsCard}>
									<UnseenReports />
								</Card>
								<Card border={false} className={classes.PatientNotesCard}>
									<PatientNotes />
								</Card>
								{containerWidth >= 628 && (
									<Card border={false} className={classes.PatientOrdersCard}>
										<PatientOrders
											hasPermission={userSecurityFlags.includes("patientview-ordersforpatient")}
											patientName={formatNameAsReversed(
												patientBannerQuery?.data?.forename,
												patientBannerQuery?.data?.surname,
												patientBannerQuery?.data?.title,
											)}
										/>
									</Card>
								)}
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
}
