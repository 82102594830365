import { Divider, TextArea } from "@streets-heaver/shui2";
import { PatientItem } from "@streetsheaver/compucore";
import { Controller, useFormContext } from "react-hook-form";
import { EpisodeOfCareCard } from "../../EpisodeOfCareCard/EpisodeOfCareCard";
import { FormField } from "../FormField/FormField";
import { Purchaser } from "./Sections/Purchaser/Purchaser";
import classes from "./AppointmentForm.module.scss";
import { EditBooking } from "./Sections/EditBooking/EditBooking";

export const EditAppointmentForm = () => {
	const methods = useFormContext();

	const notes = methods.register("notes");

	return (
		<div className={classes.appointmentForm}>
			<div className={classes.header}>
				<div className={classes.patient}>
					<PatientItem />
				</div>
				<EpisodeOfCareCard />
			</div>
			<Divider />
			<EditBooking />
			<Divider />
			<Purchaser />
			<Divider />
			<FormField label="Notes" name={notes.name}>
				<Controller
					control={methods.control}
					name={notes.name}
					render={({ field: { onChange, value } }) => (
						<TextArea type="filledDarker" size="large" value={value} onChange={onChange} />
					)}
				/>
			</FormField>
		</div>
	);
};
