import { Datebox } from "@streets-heaver/shui2";
import dayjs from "dayjs";

export const FormDatebox = ({ value, onChange, portalTarget, automationId }) => {
	return (
		<Datebox
			customWidth={160}
			size="large"
			type={"filledDarker"}
			date={value ? new Date(value) : null}
			showInitialDate={!!value}
			onSelected={(date) => {
				if (dayjs(date).isValid()) return onChange(`${dayjs(date).local().format("YYYY-MM-DD")}T00:00:00`);
			}}
			automationId={automationId}
			calendarAutomationId={`${automationId}-calendar`}
			floatingPortalTarget={portalTarget}
		/>
	);
};
