import { z } from "zod";
import dayjs from "dayjs";
import { timeRegex } from "../../../../../globals/regex";

const bookingDateSchema = z
	.date()
	.refine((value) => !dayjs(value).isBefore(dayjs(), "day"), { message: "Booking date must be in the future" });

const bookingTimeSchema = z.string().regex(timeRegex, { message: "Please select a booking time." });

export const durationSchema = z.object(
	{ value: z.union([z.number(), z.string()]) },
	{ message: "A duration must be selected" },
);

export const isFaceToFaceSchema = z.boolean();

export const contactTypeSchema = z.nullable(z.string());

export const siteSchema =
	z.string() || z.object({ recordId: z.number(), name: z.string() }, { message: "A site must be selected" });

export const newBookingSchema = {
	date: bookingDateSchema,
	time: bookingTimeSchema,
	duration: durationSchema,
	site: siteSchema,
	isFaceToFace: isFaceToFaceSchema,
	contactType: contactTypeSchema,
};

export const faceToFaceContactTypeRefinement = {
	condition: (val) => !(!val.isFaceToFace && val.contactType === null),
	args: {
		message: "Contact type is required when booking is not face to face",
		path: ["contactType"],
	},
};
