import { Lookup } from "@streets-heaver/shui2";
import { Switch } from "@streets-heaver/shui2/inputs";
import { Controller, useFormContext } from "react-hook-form";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { useContactTypesLookup } from "../../../../../api/hooks";
import { BookingAvailability } from "../BookingAvailability/BookingAvailability";

export const NewBooking = () => {
	const form = useFormContext();

	const isFaceToFaceName = "isFaceToFace";
	const contactTypeName = "contactType";

	const contactTypeQuery = useContactTypesLookup();

	return (
		<FormGroup>
			<BookingAvailability />
			<FormField label="Is face to face?" isMandatory name={isFaceToFaceName}>
				<Controller
					control={form.control}
					name={isFaceToFaceName}
					render={({ field: { onChange, value } }) => (
						<Switch checked={value} onChange={onChange} label={value ? "Yes" : "No"} />
					)}
				/>
			</FormField>
			{!form.watch(isFaceToFaceName) && (
				<div style={{ width: 340 }}>
					<FormField label="Contact type" name={contactTypeName} isMandatory>
						<Controller
							name={contactTypeName}
							control={form.control}
							render={({ field: { value, onChange } }) => (
								<Lookup
									dropdownMaxHeight={"calc(80vh - 190px)"}
									selected={value}
									setSelected={onChange}
									searchQueryData={contactTypeQuery}
								/>
							)}
						/>
					</FormField>
				</div>
			)}
		</FormGroup>
	);
};
