import { useState } from "react";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { DiscardChangesDialog, FormWrapper, PatientBookingSelection } from "../../components";
import { CancelAppointmentForm } from "../../components/FormComponents/AppointmentForms/CancelAppointmentForm";
import { cancelAppointment, addCancelAppointmentValues } from "./CancelAppointmentSchema";
import { useNavigate, useParams } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";

export const CancelAppointment = () => {
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const navigate = useNavigate();
	const { bookingId } = useParams();

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: addCancelAppointmentValues(),
		resolver: zodResolver(cancelAppointment),
	});

	const [isConfirmDiscardDialogVisible, setIsConfirmDiscardDialogVisible] = useState(false);

	const onSubmit = (data) => {
		toast.promise(
			new Promise((resolve) => {
				setTimeout(() => resolve(), 2000);
			}),
			{
				loading: "Cancelling appointment",
				error: "Error cancelling appointment",
				success: "Cancelled appointment",
			},
		);
		console.log("cancel appointment data", data);
		navigate(-1);
	};

	const onClose = () => navigate(-1);

	return bookingId ? (
		<>
			<FormProvider {...methods}>
				<FormWrapper header={"Cancel an appointment"}>
					<FormWrapper.Body>
						<CancelAppointmentForm />
					</FormWrapper.Body>
					<FormWrapper.ButtonBar
						buttons={{
							primaryButton: { onClick: methods.handleSubmit(onSubmit), children: "Cancel appointment" },
							secondaryButton: {
								onClick: () => {
									navigate(-1);
								},
								children: "Discard",
							},
						}}
					/>
				</FormWrapper>
			</FormProvider>
			<DiscardChangesDialog
				onClose={onClose}
				visible={isConfirmDiscardDialogVisible}
				setVisible={setIsConfirmDiscardDialogVisible}
			/>
		</>
	) : (
		<FormWrapper header={"Cancel an appointment"}>
			<FormWrapper.Body>
				<PatientBookingSelection
					selectedBooking={selectedBooking}
					setSelectedBooking={setSelectedBooking}
					selectedPatient={selectedPatient}
					setSelectedPatient={setSelectedPatient}
				/>
			</FormWrapper.Body>
			<FormWrapper.ButtonBar
				buttons={{
					tertiaryButton: {
						navigation: {
							to: selectedBooking
								? `../${ActionPaths.EditAppointmentPreselected(selectedBooking?.bookingUniqueId)}`
								: selectedPatient
									? `../${ActionPaths.EditPatientPreselected(selectedPatient?.uniqueId)}`
									: undefined,
						},
						children: selectedBooking ? "Edit appointment" : "Edit patient",
						disabled: !selectedPatient,
					},
					secondaryButton: { onClick: () => navigate(-1), children: "Cancel" },
					primaryButton: {
						navigation: { to: `../${ActionPaths.CancelAppointmentPreselected(selectedBooking?.bookingUniqueId)}` },
						children: "Cancel appointment",
						disabled: !selectedBooking,
					},
				}}
			/>
		</FormWrapper>
	);
};
