import { usePatientsLookup } from "../../../api/hooks";
import { useFormError } from "../../../utils";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../FormField/FormField";
import { PatientLookup } from "@streetsheaver/compucore";

export const PatientsLookup = ({ label = "Patient", name = "patient", isMandatory, automationId }) => {
	const form = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = usePatientsLookup(searchTerm);

	const error = useFormError(name);

	return (
		<FormField label={label} name={name} isMandatory={isMandatory}>
			<Controller
				name={name}
				control={form.control}
				render={({ field: { value, onChange, ref, onBlur } }) => (
					<PatientLookup
						selected={value}
						patientLinkPrefix={"../patient/"}
						onSearchTermChange={setSearchTerm}
						setSelected={onChange}
						searchQueryData={query}
						reference={ref}
						isError={error?.length > 0}
						automationId={automationId}
						onBlur={() =>
							setTimeout(() => {
								onBlur();
							}, 100)
						}
					/>
				)}
			/>
		</FormField>
	);
};
