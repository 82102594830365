import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { localFromUTC, urlDateTimeFormat } from "../../utils";
import { widgetRefetchInterval } from "../../globals/constants";

export const useClinicianAvailability = (date, enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const results = useQuery({
		queryKey: ["clinicianAvailability", currentClinician, date],
		queryFn: async () => {
			const data = await makeApiRequest(
				`${
					currentDataSource?.DataSourceId
				}/clinician/${currentClinician}/scheduler/availability?startDate=${urlDateTimeFormat(
					date,
				)}&endDate=${urlDateTimeFormat(date)}`,
			);
			if (data?.request?.response) throw new Error("Getting clinician availability Failed");
			const processedAvailability = [];
			data?.availabilities.map((availability) => {
				const availabilityStart = localFromUTC(availability?.start);
				const availabilityEnd = localFromUTC(availability?.end);
				if (availability?.type === "OP")
					processedAvailability.push({ ...availability, start: availabilityStart, end: availabilityEnd });
			});
			return { ...data, availabilities: processedAvailability };
		},
		refetchInterval: widgetRefetchInterval,
		enabled: !!currentDataSource && !!currentClinician && !!date && enabled,
	});

	return results;
};
