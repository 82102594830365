import dayjs from "dayjs";
import { localFromUTC } from "../../../../../utils";

export const isTimeInvalid = (time, date, data) => {
	if (!time || !date || !data) return true;
	const is24hAvailable = data?.outpatient24h;
	if (is24hAvailable) return false;

	const processedTime = processTime(time, date);

	return !data?.availabilities?.some((availability) => isTimeInAvailability(processedTime, availability));
};

export const isTimeInAvailability = (time, availability) => {
	const availabilityStart = localFromUTC(availability?.start);
	const availabilityEnd = localFromUTC(availability?.end);
	const smallestEndTime = time.add(5, "minutes");

	return (
		availabilityStart <= time &&
		time <= availabilityEnd &&
		availabilityStart <= smallestEndTime &&
		smallestEndTime <= availabilityEnd
	);
};

export const getAvailabilityFromTime = (time, date, data) => {
	if (!time || !date || !data) return null;
	const processedTime = processTime(time, date);

	return data?.availabilities?.find((availability) => isTimeInAvailability(processedTime, availability));
};

export const processTime = (time, date) => {
	if (typeof time !== "string") return;
	const splitTime = time.split(":");
	return dayjs(date).add(splitTime[0], "hours").add(splitTime[1], "minutes");
};
