import { useQuery } from "@tanstack/react-query";

export const useContactTypesLookup = () => {
	return useQuery({
		queryKey: ["lookup", "code-table", "contact-types"],
		queryFn: getContactType,
		refetchOnWindowFocus: false,
	});
};

const getContactType = async () => {
	await new Promise((resolve) => setTimeout(resolve, 1500));
	return contactType;
};

const contactType = [
	"Telephone",
	"Telemedicine web camera",
	"Email",
	"Short Message Service (SMS) - Text Messaging",
	"Other",
	"Microsoft Teams Consultation",
];
