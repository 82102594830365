import classes from "./EventItemCompact.module.scss";
import { faSyringe } from "@shfortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatNameAsReversed, getBookingColour, localFromUTC, timeFormat } from "../../../utils";
import { useDataSources } from "@streetsheaver/compucore";
import { NavLink } from "@streets-heaver/shui2";
import { TruncatedArray } from "../../TruncatedArray/TruncatedArray";
import { InfoRow } from "../../InfoRow/InfoRow";
import clsx from "clsx";

export const EventItemCompact = ({ ghost, appointment, height, onClick, isSelected, automationId = "event-item" }) => {
	const { currentDataSource } = useDataSources();
	const currentDateTime = new Date();
	const type = appointment?.type;
	const past = currentDateTime > appointment?.end;
	const isSuspension = type === "SUS";
	const isReservation = type === "RES";
	const isPrivate = type === "PRIV";
	const isNotAppointment = isSuspension || isReservation || isPrivate;
	const isScheduler = appointment?.renderStart || appointment?.renderEnd;
	const onItemClick = isNotAppointment ? undefined : onClick;

	return (
		<div
			onClick={onItemClick}
			className={clsx(
				classes.eventItemCompact,
				isSelected && classes.selectedEventItem,
				!isScheduler && classes.eventItemAppointments,
				onItemClick && classes.interactable,
				classes[type],
			)}
			style={{ height: height }}
			tabIndex={onItemClick ? 0 : -1}
			onKeyDown={(e) => {
				if (e.key === "Enter" && onItemClick) onItemClick();
			}}
			data-testid={automationId}
		>
			<div className={classes.appointment}>
				<div className={classes.status} style={{ backgroundColor: getBookingColour(type, past) }} />
				<div className={classes.info} style={{ padding: height > 40 ? "2px 4px 2px 0px" : "unset" }}>
					<InfoRow info={"Private"} hide={!isPrivate} isBold isCentred={isScheduler} />
					<InfoRow info={`Suspension: ${appointment?.suspensionReason}`} hide={!isSuspension} isBold isCentred />
					<InfoRow info={"Reservation"} hide={!isReservation} isBold isCentred />
					<InfoRow
						info={
							<div className={classes.patientRow}>
								<p>
									{appointment?.fullNameReverse ??
										formatNameAsReversed(appointment?.forename, appointment?.surname, appointment?.title)}
								</p>
								<NavLink
									type={"default"}
									to={`/${currentDataSource?.DataSourceId}/patient/${appointment?.patientId}`}
									canCopy
								>
									{appointment?.patientPrimaryId}
								</NavLink>
							</div>
						}
						ghost={ghost}
						hide={height < 23 || isNotAppointment}
					/>
					<InfoRow
						iconSize="medium"
						colour="three"
						icon={<FontAwesomeIcon icon={faSyringe} />}
						info={<TruncatedArray list={appointment?.plannedProcedures || appointment?.procedures} name="procedures" />}
						ghost={ghost}
						hide={(!appointment?.plannedProcedures && !appointment?.procedures) || height < 68 || isNotAppointment}
					/>
					<InfoRow
						colour="three"
						info={`${timeFormat(localFromUTC(appointment?.start))} - ${timeFormat(localFromUTC(appointment?.end))}`}
						hide={isScheduler}
					/>
				</div>
			</div>
		</div>
	);
};
