import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router";
import { useState } from "react";
import toast from "react-hot-toast";
import { useEditPatient } from "../../api/hooks";
import { DiscardChangesDialog, FormWrapper, EditPatientFormContent } from "../../components";
import { addPatientSchema } from "../AddPatient/AddPatientSchema";
import { transformPatientDataForEdit } from "../AddPatient/transformPatientData";

export const EditPatientForm = ({ data, patientId }) => {
	const [isConfirmDiscardDialogVisible, setIsConfirmDiscardDialogVisible] = useState(false);
	const navigate = useNavigate();
	const { mutateAsync: editPatient } = useEditPatient();

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: { ...data },
		resolver: zodResolver(addPatientSchema),
	});

	const onSubmit = (editedData) => {
		toast
			.promise(editPatient(transformPatientDataForEdit(editedData, data, patientId)), {
				loading: { title: "Editing patient", subtitle: `Editing patient ${patientId}` },
				success: {
					title: "Successfully edited patient",
					subtitle: `Patient ${patientId} edited`,
				},
				error: (e) => ({
					title: "Error editing patient",
					subtitle: e?.response?.data?.detail ?? `Failed editing patient ${patientId}. Please try again later.`,
				}),
			})
			.then(() => navigate(".."));
	};

	const onClose = () => navigate(-2);

	return (
		<>
			<FormProvider {...methods}>
				<FormWrapper
					header={"Edit Patient"}
					buttons={{
						primaryButton: {
							onClick: methods.handleSubmit(onSubmit),
							children: "Save",
							automationId: "edit-patient-submit",
						},
						secondaryButton: {
							onClick: () => {
								methods.formState.isDirty ? setIsConfirmDiscardDialogVisible(true) : navigate(-2);
							},
							children: "Cancel",
							automationId: "edit-patient-cancel",
						},
					}}
				>
					<FormWrapper.Body>
						<EditPatientFormContent />
					</FormWrapper.Body>
				</FormWrapper>
			</FormProvider>
			<DiscardChangesDialog
				onClose={onClose}
				visible={isConfirmDiscardDialogVisible}
				setVisible={setIsConfirmDiscardDialogVisible}
			/>
		</>
	);
};
