import { Dropdown, Textbox } from "@streets-heaver/shui2";
import { useFormContext, useWatch, Controller } from "react-hook-form";
import { useFormError } from "../../../../../utils";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { nhsVerifyStatusOptions } from "../../../../../globals/defaultOptions";
import { FormDatebox } from "../../../FormDatebox/FormDatebox";

export const Identifiers = ({ automationId }) => {
	const form = useFormContext();
	const caseNo = form.register("identifiers.caseNo");
	const nhsNo = form.register("identifiers.nhsNo");
	const hospitalId = form.register("identifiers.hospitalId");
	const nhsNumberVerifyStatus = form.register("identifiers.verificationStatus");
	const nhsNumberTraceDate = form.register("identifiers.nhsTraceDate");
	const nhsNumberVerifyStatusField = useWatch({ name: nhsNumberVerifyStatus.name });

	return (
		<div data-section id="identifiers">
			<FormGroup title="Identifiers">
				<div style={{ maxWidth: "300px" }}>
					<FormField label="Case number" name={caseNo.name}>
						<Textbox
							type={"filledDarker"}
							inputName={caseNo.name}
							onChange={caseNo.onChange}
							onBlur={caseNo.onBlur}
							reference={caseNo.ref}
							size="large"
							isError={useFormError(caseNo.name).length > 0}
							automationId={`${automationId}-caseNo`}
						/>
					</FormField>
				</div>
				<div style={{ maxWidth: "300px" }}>
					<FormField label="Hospital ID" name={hospitalId.name}>
						<Textbox
							type={"filledDarker"}
							inputName={hospitalId.name}
							onChange={hospitalId.onChange}
							onBlur={hospitalId.onBlur}
							reference={hospitalId.ref}
							size="large"
							isError={useFormError(hospitalId.name).length > 0}
							automationId={`${automationId}-hospitalId`}
						/>
					</FormField>
				</div>
				<div style={{ maxWidth: "300px" }}>
					<FormField label="NHS Number" name={nhsNo.name}>
						<Textbox
							type={"filledDarker"}
							inputName={nhsNo.name}
							onChange={nhsNo.onChange}
							onBlur={nhsNo.onBlur}
							reference={nhsNo.ref}
							size="large"
							isError={useFormError(nhsNo.name).length > 0}
							automationId={`${automationId}-nhsNo`}
						/>
					</FormField>
				</div>
				<FormField label="NHS number verify status" isMandatory>
					<Controller
						name={nhsNumberVerifyStatus.name}
						control={form.control}
						render={({ field: { value, onChange } }) => (
							<Dropdown
								placeholder={{ label: "" }}
								options={nhsVerifyStatusOptions}
								value={value}
								onChange={onChange}
								type={"filledDarker"}
								searchable
								size="large"
								portalTarget={document.body}
								automationId={`${automationId}-nhsVerify`}
							/>
						)}
					/>
				</FormField>
				{(nhsNumberVerifyStatusField?.value === "01" ||
					nhsNumberVerifyStatusField?.value === "04" ||
					nhsNumberVerifyStatusField?.value === "05") && (
					<FormField label="NHS trace date" name={nhsNumberTraceDate.name}>
						<Controller
							name={nhsNumberTraceDate.name}
							control={form.control}
							render={({ field: { value, onChange } }) => (
								<FormDatebox
									value={value}
									onChange={onChange}
									portalTarget={document.body}
									automationId={`${automationId}-nhsTraceDate`}
								/>
							)}
						/>
					</FormField>
				)}
			</FormGroup>
		</div>
	);
};
