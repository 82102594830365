import { z } from "zod";
import dayjs from "dayjs";

export const patientLookupSchema = z.object(
	{
		patientId: z.union([z.number(), z.string()]),
		canMakeBooking: z.literal(true, {
			errorMap: () => ({ message: "Patient not within allowable genders or age ranges." }),
		}),
	},
	{ message: "A valid patient must be selected" },
);

const referrerSchema = z.nullable(z.object({ referrerId: z.union([z.number(), z.string()]) })).optional();
const caseholderSchema = z.nullable(z.object({ clinician: z.union([z.number(), z.string()]) })).optional();

const lookupSchema = z.nullable(z.object({ recordId: z.union([z.number(), z.string()]) })).optional();

const referralDateSchema = z
	.date()
	.refine((value) => !dayjs(value).isAfter(dayjs(), "day"), { message: "Referral date must be in the past." })
	.optional();

export const newPatientSchema = {
	patient: patientLookupSchema,
	referralDate: referralDateSchema,
	referrer: referrerSchema,
	caseHolder: caseholderSchema,
	diagnosis: lookupSchema,
};
