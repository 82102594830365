import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useCurrentClinicians } from "../useClinicians";
import { useDataSources } from "@streetsheaver/compucore";

export const usePatientsLookup = (searchText) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const query = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["lookup", "patients", searchText],
		queryFn: async ({ pageParam }) => {
			const data = await getPatients(currentDataSource?.DataSourceId, currentClinician, pageParam, 12, searchText);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician,
	});

	return {
		...query,
		data: query?.data?.pages?.flatMap((page) => page?.data).map((data) => data),
	};
};

const getPatients = async (dataSourceId, clinicianId, skip = 0, take = 12, searchText = "") => {
	const data = await makeApiRequest(
		`${dataSourceId}/clinician/${clinicianId}/lookup/patients?skip=${skip}&take=${take + 1}${
			searchText !== "" ? `&searchtext=${encodeURIComponent(searchText)}` : ""
		}`,
		{},
		"v2",
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};
