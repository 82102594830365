import { editBookingSchema } from "../../components/FormComponents/AppointmentForms/Sections/EditBooking/editBookingSchema";
import { faceToFaceContactTypeRefinement } from "../../components/FormComponents/AppointmentForms/Sections/NewBooking/newBookingSchema";
import {
	purchaserMembershipNumberRefinement,
	purchaserSchema,
} from "../../components/FormComponents/AppointmentForms/Sections/Purchaser/purchaserSchema";
import { z } from "zod";

export const editAppointment = z
	.object({
		...editBookingSchema,
		...purchaserSchema,
		notes: z.string().optional(),
	})
	.refine(faceToFaceContactTypeRefinement.condition, faceToFaceContactTypeRefinement.args)
	.refine(purchaserMembershipNumberRefinement.condition, purchaserMembershipNumberRefinement.args);
