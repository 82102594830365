import { useState } from "react";
import classes from "./EventItem.module.scss";
import { Button, Avatar, MenuButton, NavLink } from "@streets-heaver/shui2";
import { faCalendarDays as faCalendarDaysSolid, faPen as faPenSolid } from "@shfortawesome/pro-solid-svg-icons";
import { faCalendarDays as faCalendarDaysRegular, faPen as faPenRegular } from "@shfortawesome/pro-regular-svg-icons";
import { faCalendarDays, faCalendarRange, faSyringe, faPen, faNote } from "@shfortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	formatNameAsInitials,
	formatNameAsReversed,
	addToCalendarIndividualBooking,
	getBookingColour,
	getAppointmentType,
	localFromUTC,
} from "../../utils";
import { EventItemCompact } from "./EventItemCompact/EventItemCompact";
import { TimeDisplay } from "../TimeDisplay/TimeDisplay";
import { TruncatedArray } from "../TruncatedArray/TruncatedArray";
import { InfoRow } from "../InfoRow/InfoRow";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { useDataSources, usePreviewPanel } from "@streetsheaver/compucore";
import clsx from "clsx";

export const EventItem = ({
	ghost,
	appointment,
	height = 66,
	compact = false,
	onClick,
	setSelectedBooking,
	isSelected,
	automationId = "event-item",
}) => {
	const { currentDataSource } = useDataSources();
	const { setIsSheetMaximised, setIsVisible: setPreviewPanelVisible } = usePreviewPanel();
	const [showMenu, setShowMenu] = useState(false);
	const currentDateTime = new Date();
	const past = currentDateTime > appointment?.end;
	const type = appointment?.type;
	const isSuspension = type === "SUS";
	const isReservation = type === "RES";
	const isPrivate = type === "PRIV";
	const isNotAppointment = isSuspension || isReservation || isPrivate;
	const onItemClick = isNotAppointment ? undefined : onClick;

	return compact ? (
		<EventItemCompact
			ghost={ghost}
			onClick={onClick}
			appointment={appointment}
			height={height}
			automationId={automationId}
			isSelected={isSelected}
		/>
	) : (
		<div
			data-testid={automationId}
			onMouseEnter={() => {
				setShowMenu(true);
			}}
			onMouseLeave={() => {
				setShowMenu(false);
			}}
			onClick={onItemClick}
			onKeyDown={(e) => {
				if (e.key === "Enter") onItemClick();
			}}
			tabIndex={onItemClick ? 0 : -1}
			className={clsx(classes.EventItem, isSelected && classes.SelectedEventItem, classes[type])}
			style={{
				minHeight: "66px",
				cursor: !onItemClick ? "initial" : "pointer",
			}}
		>
			<div className={classes.Appointment}>
				<div className={classes.Time} data-testid="appointment-time-details">
					<TimeDisplay start={localFromUTC(appointment?.start)} end={localFromUTC(appointment?.end)} column />
				</div>
				<div className={classes.Status} style={{ backgroundColor: getBookingColour(type, past), minHeight: height }} />
				<div className={classes.Info}>
					<InfoRow
						iconSize="largePaddingless"
						icon={
							<Avatar
								ghost={ghost}
								initials={formatNameAsInitials(appointment?.forename, appointment?.surname)}
								image={appointment?.pictureUrl}
								size={20}
							/>
						}
						info={
							<div className={classes.PatientRow}>
								<p>{formatNameAsReversed(appointment?.forename, appointment?.surname, appointment?.title)}</p>
								<NavLink
									type={"default"}
									to={`/${currentDataSource?.DataSourceId}/patient/${appointment?.patientId}`}
									canCopy
								>
									{appointment?.patientPrimaryId}
								</NavLink>
							</div>
						}
						ghost={ghost}
						hide={isNotAppointment || height < 23}
					/>
					<InfoRow info={"Private"} hide={!isPrivate} isBold isCentred />
					<InfoRow info={`Suspension: ${appointment?.suspensionReason}`} hide={!isSuspension} isBold isCentred />
					<InfoRow info={"Reservation"} hide={!isReservation} isBold isCentred />
					<InfoRow
						info={<p className={classes.privateMessage}>You do not have permission to view this booking.</p>}
						hide={!isPrivate}
					/>
					<InfoRow
						iconSize="medium"
						colour="three"
						icon={<FontAwesomeIcon icon={faSyringe} />}
						info={<TruncatedArray list={appointment?.plannedProcedures} name="procedures" />}
						ghost={ghost}
						hide={!appointment?.plannedProcedures || appointment?.plannedProcedures?.length < 1 || isNotAppointment}
					/>
					<InfoRow
						iconSize="medium"
						colour="three"
						icon={<FontAwesomeIcon icon={faCalendarRange} />}
						info="Follow up"
						hide={!appointment?.isFollowUp}
					/>
					<InfoRow
						iconSize="medium"
						colour="three"
						icon={<FontAwesomeIcon icon={faNote} />}
						info={
							<div className={classes.NotesButton}>
								<Button
									size="smaller"
									type="transparent"
									onClick={(e) => {
										e.stopPropagation();
										setSelectedBooking({
											id: appointment?.bookingUniqueId,
											type: getAppointmentType(type),
											overrideTab: "Notes",
										});
										setPreviewPanelVisible(true);
										setIsSheetMaximised(true);
									}}
								>
									Notes
								</Button>
							</div>
						}
						ghost={ghost}
						hide={!appointment?.hasNotes}
						style={{ gap: "unset" }}
					/>
				</div>
			</div>
			<div
				className={classes.Actions}
				style={{ visibility: showMenu ? "visible" : "hidden" }}
				onClick={(e) => {
					e.stopPropagation();
				}}
			>
				{showMenu && !isPrivate && (
					<>
						<Button
							size={"large"}
							type="transparent"
							colour="grey"
							icon={{ restIcon: faCalendarDays, hoverIcon: faCalendarDaysRegular, pressedIcon: faCalendarDaysSolid }}
							onClick={(e) => {
								e.preventDefault();
								addToCalendarIndividualBooking({ ...appointment });
							}}
							tooltipText="Add to calendar"
							tooltipWidth={120}
							hasBackground={true}
						/>
						<MenuButton
							icon={{ restIcon: faPen, hoverIcon: faPenRegular, pressedIcon: faPenSolid, selectedIcon: faPenSolid }}
							type={"subtle"}
							size={"large"}
							colour="grey"
							menuProps={{
								items: [
									{
										items: [
											{
												text: "Edit Appointment",
												to: ActionPaths.EditAppointmentPreselected(appointment?.bookingUniqueId),
											},
											{
												text: "Cancel Appointment",
												to: ActionPaths.CancelAppointmentPreselected(appointment?.bookingUniqueId),
											},
										],
									},
								],
							}}
							portalTarget={document.body}
							automationId={`${automationId}-edit`}
						/>
						{appointment?.videoConsultLink && (
							<Button type={"primary"} size={"large"} navigation={{ to: appointment?.videoConsultLink }}>
								Join
							</Button>
						)}
					</>
				)}
			</div>
		</div>
	);
};
