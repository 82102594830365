import { Controller } from "react-hook-form";
import { Datebox } from "../../../../Datebox/Datebox";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { DiagnosesLookup } from "../../../lookups/DiagnosesLookup";
import { ReferrersLookup } from "../../../lookups/ReferrersLookup";
import { CliniciansLookup } from "../../../lookups/CliniciansLookup";
import { PatientsLookup } from "../../../lookups/PatientsLookup";

export const NewPatient = () => {
	const referralDateName = "referralDate";

	return (
		<FormGroup>
			<PatientsLookup isMandatory />
			<FormField label="Referral date" name={referralDateName}>
				<Controller
					name={referralDateName}
					render={({ field: { value, onChange } }) => (
						<Datebox
							customWidth={160}
							size="large"
							type={"filledDarker"}
							date={value}
							showInitialDate={value}
							onSelected={(date) => onChange(new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())))}
						/>
					)}
				/>
			</FormField>
			<ReferrersLookup />
			<CliniciansLookup label="Lead case holder" />
			<DiagnosesLookup />
		</FormGroup>
	);
};
