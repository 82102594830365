import { Datebox, Dropdown } from "@streets-heaver/shui2";
import { Timebox } from "@streets-heaver/shui2/inputs";
import { useDebounce } from "@streetsheaver/compucore";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../../../FormField/FormField";
import { SiteLookup } from "../../../lookups/SiteLookup";
import { useClinicianAvailability } from "../../../../../api/hooks/useClinicianAvailability.js";
import { getDurationOptions } from "./durationOptions.js";
import { getAvailabilityFromTime, isTimeInvalid } from "./timeOptions.js";
import {
	timeHelperText,
	dateErrorMessage,
	timeErrorMessage,
	durationHelperText,
} from "../../../../../globals/messages.js";
import { useEffect, useState } from "react";

export const BookingAvailability = () => {
	const { control, watch, setValue, setError, clearErrors, resetField, trigger } = useFormContext();
	const [selectedAvailability, setSelectedAvailability] = useState();

	const dateName = "date";
	const timeName = "time";
	const durationName = "duration";
	const siteName = "site";

	const selectedDate = watch(dateName);
	const selectedTime = watch(timeName);

	const debouncedDate = useDebounce(selectedDate, 600);
	const debouncedTime = useDebounce(selectedTime, 600);

	const { data, isLoading } = useClinicianAvailability(debouncedDate, true);
	const durationOptions = getDurationOptions(debouncedTime, debouncedDate, selectedAvailability);

	const noAvailabilityError = !isLoading && !data?.outpatient24h && data?.availabilities?.length < 1;
	const invalidTimeError = isTimeInvalid(debouncedTime, debouncedDate, data);

	useEffect(() => {
		if (debouncedTime && debouncedDate && data) {
			const availability = getAvailabilityFromTime(debouncedTime, debouncedDate, data);
			setSelectedAvailability(availability);
		}
	}, [setSelectedAvailability, debouncedTime, debouncedDate, data, resetField]);

	useEffect(() => {
		if (selectedAvailability) setValue(siteName, selectedAvailability?.site);
	}, [setValue, selectedAvailability, debouncedTime, trigger]);

	useEffect(() => {
		if (noAvailabilityError) {
			setError(dateName, { message: dateErrorMessage });
			resetField(timeName, { defaultValue: "" });
			resetField(durationName, { defaultValue: null });
		} else {
			clearErrors(dateName);
		}
	}, [noAvailabilityError, setError, clearErrors, resetField, isLoading]);

	useEffect(() => {
		if (invalidTimeError) {
			setError(timeName, { message: timeErrorMessage });
			resetField(timeName, { defaultValue: "", keepError: true });
			resetField(durationName, { defaultValue: null });
		} else {
			clearErrors(timeName);
		}
	}, [invalidTimeError, debouncedTime, setError, clearErrors, resetField]);

	useEffect(() => {
		resetField(durationName, { defaultValue: null });
	}, [debouncedTime, resetField]);

	return (
		<>
			<FormField label="Date" name={dateName} isMandatory>
				<Controller
					name={dateName}
					control={control}
					render={({ field: { value, onChange, error } }) => (
						<Datebox
							customWidth={160}
							size="large"
							type={"filledDarker"}
							date={value}
							showInitialDate={typeof value !== "undefined"}
							onSelected={(e) => {
								onChange(e);
							}}
							isError={error}
							floatingPortalTarget={document.body}
						/>
					)}
				/>
			</FormField>
			<div style={{ width: 240 }}>
				<FormField label="Time" name={timeName} isMandatory helperText={timeHelperText}>
					<Controller
						name={timeName}
						control={control}
						render={({ field: { value, onChange, ref, error } }) => (
							<Timebox
								type="filledDarker"
								size="large"
								dropdownStep={15}
								inputStep={15}
								value={value}
								onChange={(e) => {
									onChange(e);
								}}
								ref={ref}
								disabled={!data || isLoading || noAvailabilityError}
								isTimeDisabled={(time) => isTimeInvalid(time, debouncedDate, data)}
								hasError={error}
								portalTarget={document.body}
							/>
						)}
					/>
				</FormField>
			</div>
			<div style={{ width: 240 }}>
				<FormField label="Duration" name={durationName} isMandatory helperText={durationHelperText}>
					<Controller
						name={durationName}
						control={control}
						render={({ field: { value, onChange, ref, error } }) => (
							<Dropdown
								placeholder={{ label: "Select a duration" }}
								value={value}
								onChange={onChange}
								type={"filledDarker"}
								size="large"
								options={!!durationOptions && durationOptions}
								loading={isLoading}
								disabled={!data || noAvailabilityError || !selectedTime || !durationOptions}
								reference={ref}
								isError={error}
								portalTarget={document.body}
							/>
						)}
					/>
				</FormField>
			</div>
			<div style={{ width: 340 }}>
				<SiteLookup name={siteName} isViewOnly={!data?.outpatient24h} isMandatory />
			</div>
		</>
	);
};
