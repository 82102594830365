import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AddAppointmentForm } from "../../components/FormComponents";
import { DiscardChangesDialog, FormWrapper, NoAccessPage } from "../../components";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import {
	addAppointmentNewDefaultValues,
	addAppointmentNew,
	addAppointmentFollowUpDefaultValues,
	addAppointmentFollowUp,
} from "./AddAppointmentSchema";
import toast from "react-hot-toast";

export const AddAppointment = ({ type }) => {
	const [appointmentType, setAppointmentType] = useState(type);
	const isFollowUp = appointmentType === "follow-up";
	const { userSecurityFlags } = useCurrentClinicians();
	const location = useLocation();
	const navigate = useNavigate();

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: isFollowUp
			? addAppointmentFollowUpDefaultValues(location.state?.date, location.state?.time, location.state?.duration)
			: addAppointmentNewDefaultValues(location.state?.date, location.state?.time, location.state?.duration),
		resolver: zodResolver(isFollowUp ? addAppointmentFollowUp : addAppointmentNew),
	});

	const [isConfirmDiscardDialogVisible, setIsConfirmDiscardDialogVisible] = useState(false);
	const onSubmit = (data) => {
		toast.promise(
			new Promise((resolve) => {
				setTimeout(() => resolve(), 2000);
			}),
			{
				loading: "Adding new appointment",
				error: "Error adding new appointment",
				success: "Added new appointment",
			},
		);
		console.log("add new appointment data", data);
		navigate(-1);
	};

	const onClose = () => navigate(-1);

	useEffect(() => {
		setAppointmentType(type);
		methods.reset();
	}, [type, methods]);

	if (!userSecurityFlags.includes("appointment-addappointment")) {
		return <NoAccessPage />;
	}

	return (
		<>
			<FormProvider {...methods}>
				<FormWrapper header={"Create an appointment"}>
					<FormWrapper.Body>
						<AddAppointmentForm type={appointmentType} />
					</FormWrapper.Body>
					<FormWrapper.ButtonBar
						buttons={{
							primaryButton: {
								onClick: methods.handleSubmit(onSubmit),
								children: "Add appointment",
							},
							secondaryButton: {
								onClick: () => setIsConfirmDiscardDialogVisible(true),
								children: "Cancel",
							},
						}}
					/>
				</FormWrapper>
			</FormProvider>
			<DiscardChangesDialog
				onClose={onClose}
				visible={isConfirmDiscardDialogVisible}
				setVisible={setIsConfirmDiscardDialogVisible}
			/>
		</>
	);
};
