import { processTime } from "./timeOptions";
import { localFromUTC } from "../../../../../utils";
import { defaultDurationOptions } from "../../../../../globals/defaultOptions";

export const getDurationOptions = (time, date, availability) => {
	const durationOptions = [];
	if (!time || !date || !availability) return durationOptions;
	const processedTime = processTime(time, date);

	if (!availability) return durationOptions;

	defaultDurationOptions?.map((duration) => {
		if (isDurationValid(availability, processedTime, duration?.value)) durationOptions.push(duration);
	});

	return durationOptions;
};

export const isDurationValid = (availability, time, duration) => {
	const availabilityStart = localFromUTC(availability?.start);
	const availabilityEnd = localFromUTC(availability?.end);
	const approxEndTime = time.add(duration, "minutes");

	return availabilityStart <= approxEndTime && approxEndTime <= availabilityEnd;
};
